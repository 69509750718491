import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, Observable, timer } from 'rxjs';

import { AlertStatus } from '../models/enums/alert-status.enum';
import { Alert, AlertConfig } from '../models/interfaces/alert.interface';

@Injectable({ providedIn: 'root' })
export class AlertService {
  public activeAlerts$: Observable<Alert[]>;

  private activeAlerts = new BehaviorSubject<Alert[]>([]);

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    this.activeAlerts$ = this.activeAlerts.asObservable();
  }

  public show(alertConfig: AlertConfig): void {
    const alert: Alert = { ...alertConfig };

    if (this.activeAlerts.value.length === 3) {
      this.deleteAlert(this.activeAlerts.value[0].id);
    }

    const currentActiveAlerts = this.activeAlerts.value;
    alert.id = currentActiveAlerts.length;
    alert.timer = timer(
      alert.duration || (alert.status === AlertStatus.Error ? 10000 : 3000)
    ).subscribe(() => {
      this.deleteAlert(alert.id);
    });

    if (isPlatformBrowser(this.platformId) && !window.navigator.onLine) {
      alert.title = $localize`Нет подключения к сети`;
      alert.message = $localize`Пожалуйста, проверьте соединение и повторите попытку`;
      alert.status = null;
    }

    currentActiveAlerts.push(alert);

    this.activeAlerts.next(currentActiveAlerts);
  }

  public deleteAlert(id: number): void {
    const currentActiveAlerts = this.activeAlerts.value;

    this.activeAlerts.next(
      currentActiveAlerts.filter(alert => {
        if (alert.id === id) {
          alert.timer.unsubscribe();
        }

        return alert.id !== id;
      })
    );
  }
}
